import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { getPayloadObject, getTelemetryAttributes, IPopupProps, ITelemetryContent, Popup } from '@msdyn365-commerce-modules/utilities';
import { PriceComponent } from '@msdyn365-commerce/components';
import { IComponent, IComponentProps, IGridSettings, IImageSettings, TelemetryEvent } from '@msdyn365-commerce/core';
import { getCartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import { ProductAvailableQuantity, ProductDimension, ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { getAttributeValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { DobbiesModal } from '../custom-components';

export interface IAddToCartComponentProps extends IComponentProps<{ product: SimpleProduct; price?: ProductPrice }> {
    className?: string;
    addToCartText: string;
    outOfStockText: string;
    disabled?: boolean;
    quantity?: number;
    navigationUrl?: string;
    productAvailability?: ProductAvailableQuantity;
    getSelectedProduct?: Promise<SimpleProduct | null>;

    imageSettings?: IImageSettings;
    gridSettings?: IGridSettings;

    isLoading?: boolean;
    isUpdatingDimension?: boolean;
    isAddServiceItemToCart?: boolean;
    /* Upgraded to 10.0.16 - START */
    isLoadingDeliveryOptions?: boolean;
    isUpdatingDeliveryOptions?: boolean;
    isAddEmailDeliveryItemToCart?: boolean;
    isPriceKeyedIn?: boolean;
    customPriceAmount?: number;
    isOrderQuantityLimitsFeatureEnabled?: boolean;
    /* Upgraded to 10.0.16 - END */
    dialogStrings?: {
        goToCartText: string;
        continueShoppingText: string; // popupHeading
        headerItemOneText: string; // ageConfirmationHeaderText
        headerItemFormatText: string; // ageConfirmationText
        headerMessageText: string; // ageConfirmationFooterText
        freePriceText: string;
        originalPriceText: string;
        currentPriceText: string;
        ageRestrictionPopupHeading: string;
        ageRestrictionNote: string;
        ageConfirmation: string;
        ageVerificationDetails: string;
        cancelButtonLabel: string;
        buyBoxHeaderMessageText: string;
        buyBoxGoToCartText: string;
    };
    telemetryContent?: ITelemetryContent;
    /* Upgraded to 10.0.16 - START */
    isCustomPriceSelected?: boolean;
    maximumKeyInPrice?: number;
    minimumKeyInPrice?: number;
    defaultMaximumKeyInPrice?: number;
    defaultMinimumKeyInPrice?: number;
    /* Upgraded to 10.0.16 - END */
    onAdd?(result: ICartActionResult): void;
    onError?(result: IAddToCartFailureResult): void;
    changeUpdatingDimension?(isUpdatingDimension: boolean): void;
    /* Upgraded to 10.0.16 - START */
    changeUpdatingDeliveryOptions?(isUpdatingDeliveryOptions: boolean): void;
    /* Upgraded to 10.0.16 - END */
}

export declare type ICartActionFailureReason =
    | 'EMPTYINPUT'
    | 'MISSINGDIMENSION'
    | 'OUTOFSTOCK'
    | 'CARTACTIONFAILED'
    | 'INVALIDCUSTOMAMOUNT'; /* Upgraded to 10.0.16 - INVALIDCUSTOMAMOUNT added */
export interface IAddToCartFailureResult {
    failureReason: ICartActionFailureReason;

    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

export interface IAddtoCartComponent extends IComponent<IAddToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: IAddToCartComponentProps) => void;
}

const onClick = async (
    _event: React.MouseEvent<HTMLElement>,
    props: IAddToCartComponentProps,
    setDisabled: (disabled: boolean) => void,
    openModal: (opened: boolean) => void,
    firstCase: boolean,
    toggleFirstCase: (firstCase: boolean) => void,
    setAddtoCartModal: (disabled: boolean) => void,
    setChecked: (checked: boolean) => void,
    isAgeRestricted: boolean
): Promise<void> => {
    const cartError = addToCartError(props);
    let productToAdd = props.data.product;

    if (cartError) {
        propogateError(props, cartError);
        return;
    }
    setDisabled(true);

    if (!(props.getSelectedProduct === undefined)) {
        productToAdd = (await props.getSelectedProduct) || props.data.product;
    }
    /* VSI Customization - START - 05/11/20 */
    /* If condition checks if product has age restriciton and 'Addtobasket' is clicked for the first time, open age restrcition popup */
    if (firstCase === true && isAgeRestricted === true) {
        toggleFirstCase(false);
        setAddtoCartModal(true);
        setDisabled(false);
        setChecked(false);
        /* VSI Customization - END */
    } else {
        const cartState = await getCartState(props.context.actionContext);
        const {
            context: {
                app: { config }
            }
        } = props;
        const behavior = config && config.addToCartBehavior;
        await cartState
            .addProductToCart({
                product: productToAdd,
                count: props.quantity,
                availableQuantity: props.productAvailability && props.productAvailability.AvailableQuantity,
                additionalProperties: {
                    orderQuantityLimitsFeatureIsEnabled: props.isOrderQuantityLimitsFeatureEnabled
                } /* Upgraded to 10.0.16 */,
                enableStockCheck: props.context.app.config.enableStockCheck,
                /* Upgraded to 10.0.16 - START */
                isPriceKeyedIn: props.isPriceKeyedIn,
                customPrice: props.customPriceAmount,
                isAddEmailDeliveryItemToCart: props.isAddEmailDeliveryItemToCart
                /* Upgraded to 10.0.16 - END */
            })
            .then(result => {
                if (result.status === 'SUCCESS') {
                    if (props.dialogStrings && behavior === 'showModal') {
                        setDisabled(false);
                        openModal(true);
                    } else if (props.navigationUrl && (behavior === undefined || behavior === 'nothing' || behavior === 'goToCart')) {
                        /* VSI Customization - START - 26/10/20 */
                        // Added addToCartBehavior === 'nothing' in if condition do the same when this settings are selected
                        /* When user clicks on 'ADD TO BAG' button,
                            - It would just ADD Product to CART and will not route to CART page.
                            - 'ADD TO BAG' button will not be disabled */
                        // window && window.location.assign(props.navigationUrl);
                        setDisabled(false);
                        setAddtoCartModal(false);
                        /* VSI Customization - END */
                    }
                    propogateResult(props, result);
                } else {
                    propogateError(props, { failureReason: 'CARTACTIONFAILED', cartActionResult: result });
                    setDisabled(false);
                }
            });
    }
};

const AddToCartComponentActions = {
    onClick: onClick
};

/* VSI Customization - START - 06/11/20 */
/* This function renders 'Add to Basket' button.
popupAttributes decided whether to render it on PDP or on popup
if rendered on popup, it has additional attributes */
const _getAddtoBasketButton = (
    props: IAddToCartComponentProps,
    _disabled: boolean,
    onClickHandler: (event: React.MouseEvent<HTMLElement>) => Promise<void>,
    popupAttributes?:
        | {
              disabled: boolean;
              onClick(event: React.MouseEvent<HTMLElement>): Promise<void>;
          }
        | {
              disabled: boolean;
              onClick?: undefined;
          }
) => {
    const label = getLinkText(props);
    const payload = getPayloadObject(TelemetryEvent.AddToCart, props.telemetryContent!, label, '');
    const attributes = getTelemetryAttributes(props.telemetryContent!, payload);
    const _popupAttributes = popupAttributes ? popupAttributes : undefined;
    return (
        <button
            className={classnames('msc-add-to-cart ', props.className)}
            aria-label={getLinkText(props)}
            {...attributes}
            onClick={onClickHandler}
            disabled={props.disabled || _disabled || isIntermediateState(props) || shouldShowOutOfStock(props, false)}
            {..._popupAttributes}
        >
            {getLinkText(props)}
        </button>
    );
};
/* VSI Customization - START - 05/11/20 */

const AddToCart: React.FC<IAddToCartComponentProps> = (props: IAddToCartComponentProps) => {
    const [disabled, setDisabled] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    /* VSI Customization - START - 26/10/20
    /* Following hook is used for age restriction popup.
    By default its value is true to handle first click and When user clicks 'Add to Cart' button for first time it sets to false for rest of the clicks */
    const [firstCase, toggleFirstCase] = useState(true);
    /* Following hook is used to open age restriction popup  */
    const [isAddToCartModalOpen, setAddtoCartModal] = useState(false);
    /* Following hook is used to toggle checkbox on age restriction popup */
    const [checked, setChecked] = useState(false);
    const [isAgeRestricted, SetIsAgeRestricted] = useState(false);
    useEffect(() => {
        _isAgeRestrictedProduct(props, SetIsAgeRestricted).catch(err => {
            return;
        });
    });
    /* Added one more parameter 'setAddedtoCart' to onClick method*/
    const onClickHandler = async (event: React.MouseEvent<HTMLElement>) => {
        await AddToCartComponentActions.onClick(
            event,
            props,
            setDisabled,
            setModalOpen,
            firstCase,
            toggleFirstCase,
            setAddtoCartModal,
            setChecked,
            isAgeRestricted
        );
    };
    /* VSI Customization - END */

    const priceComponent = props.data.price ? (
        <PriceComponent
            data={{ price: props.data.price }}
            context={props.context}
            id={props.id}
            typeName={props.typeName}
            freePriceText={props.dialogStrings && props.dialogStrings.freePriceText}
            originalPriceText={props.dialogStrings && props.dialogStrings.originalPriceText}
            currentPriceText={props.dialogStrings && props.dialogStrings.currentPriceText}
        />
    ) : (
        ''
    );

    const popupProps: IPopupProps = {
        context: props.context,
        className: 'msc-add-to-cart',
        id: props.id,
        typeName: props.typeName,
        data: { product: props.data.product, price: props.data.price },
        dialogStrings: props.dialogStrings,
        imageSettings: props.imageSettings,
        gridSettings: props.context.request.gridSettings,
        productQuantity: props.quantity !== undefined ? props.quantity : 1,
        priceComponent: priceComponent,
        navigationUrl: props.navigationUrl,
        modalOpen: modalOpen,
        setModalOpen: setModalOpen
    };

    const renderModalPopup = <Popup {...popupProps} />;
    return (
        <>
            {renderModalPopup}
            {/* VSI Customization - START - 26/10/20 */}
            {/* Moved AddtoBasket button into a function as it is used twice one on PDP page, second on age restriction popup */}
            {_getAddtoBasketButton(props, disabled, onClickHandler)}
            {/* DobbiesModal is used for popups i.e. age restriction popup */}
            {props.dialogStrings && (
                <DobbiesModal
                    isOpen={isAddToCartModalOpen}
                    modalTitle={addToCartModalTitle(setAddtoCartModal, toggleFirstCase, setChecked, props)}
                    modalBodyElement={addToCartModalBody(checked, setChecked, props)}
                    modalFooter={addToCartModalFooter(
                        checked,
                        setChecked,
                        onClickHandler,
                        setAddtoCartModal,
                        toggleFirstCase,
                        props,
                        disabled
                    )}
                    className='msc-dobbies-modal__add-to-cart-modal'
                />
            )}
        </>
    );
};
/* VSI Customization - START - 05/11/20 */
/* Following function returns attributes of current product */
const _isAgeRestrictedProduct = async (_props: IAddToCartComponentProps, SetIsAgeRestricted: (isAgeRestricted: boolean) => void) => {
    const {
        data: {
            product: { RecordId }
        },
        context: {
            actionContext: {
                requestContext: {
                    apiSettings: { channelId, catalogId }
                }
            }
        }
    } = _props;
    if (RecordId) {
        const callerContext = _props.context.actionContext;
        const customAttributes = await getAttributeValuesAsync({ callerContext, queryResultSettings: {} }, RecordId, channelId, catalogId);
        const ageRestrictedAttribute =
            customAttributes &&
            customAttributes.find(customAttribute => {
                const attributeName = customAttribute.Name && customAttribute.Name.trim().toLowerCase();
                return attributeName === 'agerestriction';
            });
        // check if product has age restriction
        ageRestrictedAttribute &&
            ageRestrictedAttribute.TextValue &&
            ageRestrictedAttribute.TextValue.trim().toLowerCase() === 'true' &&
            SetIsAgeRestricted(true);
    }
};
// Following function renders popup header
const addToCartModalTitle = (
    setAddtoCartModal: (disabled: boolean) => void,
    toggleFirstCase: (firstCase: boolean) => void,
    setChecked: (checked: boolean) => void,
    props: IAddToCartComponentProps
): JSX.Element => {
    const closeModal = () => {
        setAddtoCartModal(false);
        toggleFirstCase(true);
        setChecked(false);
    };

    const { dialogStrings } = props;

    return (
        <div className='age-restriction-header'>
            {dialogStrings && <h3 className='age-restriction-subheading'>{dialogStrings.ageRestrictionPopupHeading}</h3>}
            <span role='button' onClick={closeModal} className='addtocart-cross' />
        </div>
    );
};
// Following function renders popup body including checkbox
const addToCartModalBody = (checked: boolean, setChecked: (checked: boolean) => void, props: IAddToCartComponentProps): JSX.Element => {
    function toggleCheckBox(): void {
        setChecked(!checked);
    }

    const { dialogStrings } = props;

    return (
        <>
            {dialogStrings ? (
                <div className='age-restriction-body'>
                    <p className='age-restriction_note'>{dialogStrings.ageRestrictionNote}</p>
                    <label className='age-restriction_confirmation'>
                        <input
                            className='age-restriction_confirmation-checkbox'
                            type='checkbox'
                            onClick={toggleCheckBox}
                            aria-checked={checked}
                            checked={checked}
                        />
                        <span className='age-restriction_confirmation-text'>{dialogStrings.ageConfirmation}</span>
                    </label>
                    <p className='age-restriction_requirement'>{dialogStrings.ageVerificationDetails}</p>
                </div>
            ) : (
                undefined
            )}
        </>
    );
};
// Following function renders popup footer including 'Add to Basket' button
const addToCartModalFooter = (
    checked: boolean,
    setChecked: (checked: boolean) => void,
    onClickHandler: (event: React.MouseEvent<HTMLElement>) => Promise<void>,
    setAddtoCartModal: (disabled: boolean) => void,
    toggleFirstCase: (firstCase: boolean) => void,
    props: IAddToCartComponentProps,
    disabled: boolean
): JSX.Element => {
    const closeModal = () => {
        setAddtoCartModal(false);
        toggleFirstCase(true);
        setChecked(false);
    };
    // if checkbox is checked, only then 'Add to Basket' button will be enabled
    const popupAttributes = checked ? { disabled: false, onClick: onClickHandler } : { disabled: true };
    return (
        <div className='age-restriction-footer'>
            <button onClick={closeModal} className='msc-btn add-to-cart__age-restriction'>
                {props.dialogStrings && props.dialogStrings.cancelButtonLabel}
            </button>
            {_getAddtoBasketButton(props, disabled, onClickHandler, popupAttributes)}
        </div>
    );
};
/* VSI Customization - END - 06/11/20 */
// Set default props
AddToCart.defaultProps = {
    quantity: 1
};

const getLinkText = (props: IAddToCartComponentProps): string => {
    return shouldShowOutOfStock(props, false) ? props.outOfStockText : props.addToCartText;
};

const addToCartError = (props: IAddToCartComponentProps): IAddToCartFailureResult | undefined => {
    /* Upgraded to 10.0.16 - START */
    const {
        data,
        productAvailability,
        isCustomPriceSelected,
        customPriceAmount,
        maximumKeyInPrice,
        minimumKeyInPrice,
        defaultMaximumKeyInPrice = 100,
        defaultMinimumKeyInPrice = 10
    } = props;
    /* Upgraded to 10.0.16 - END */
    if (!data || !data.product.RecordId) {
        // No product exists, won't be able to add to cart
        return { failureReason: 'EMPTYINPUT' };
    }

    if (data.product.Dimensions) {
        const missingDimensions = data.product.Dimensions.filter(
            dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value)
        );

        if (missingDimensions.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to add to cart
            return { failureReason: 'MISSINGDIMENSION', missingDimensions: missingDimensions };
        }
    }

    if (shouldShowOutOfStock(props, true)) {
        const availableQuantity = (productAvailability && productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity, 0);

        return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
    }
    /* Upgraded to 10.0.16 - START */
    // When Custom price is selected, if there is no keyed-in price or keyed-in price is out of limit, should return error.
    if (
        isCustomPriceSelected &&
        (!customPriceAmount ||
            customPriceAmount > (maximumKeyInPrice || defaultMaximumKeyInPrice) ||
            customPriceAmount < (minimumKeyInPrice || defaultMinimumKeyInPrice))
    ) {
        return { failureReason: 'INVALIDCUSTOMAMOUNT' };
    }
    /* Upgraded to 10.0.16 - END */
    // Only allow adding to cart if not showing out of stock
    return undefined;
};

const shouldShowOutOfStock = (props: IAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
    if (
        props.context.app.config.enableStockCheck === undefined ||
        props.context.app.config.enableStockCheck === false ||
        props.isLoading ||
        props.isUpdatingDimension ||
        props.isAddServiceItemToCart ||
        /* Upgraded to 10.0.16 - START */
        props.isLoadingDeliveryOptions ||
        props.isUpdatingDeliveryOptions
        /* Upgraded to 10.0.16 - END */
    ) {
        // Out of stock turn off, don't bother showing out of stock
        return false;
    }

    if (!props.data || !props.data.product.RecordId) {
        // No product exists, don't bother showing out of stock
        return false;
    }

    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also don't show out of stock
            return false;
        }
    }
    const includedQuantityNumber = includeCurrentQuantity && props.quantity ? props.quantity : 1;

    return props.productAvailability &&
        props.productAvailability.AvailableQuantity !== undefined &&
        props.productAvailability.AvailableQuantity >= includedQuantityNumber
        ? false
        : true;
};

const isIntermediateState = (props: IAddToCartComponentProps): boolean => {
    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also not in intermediate state
            return false;
        }
    }

    if (
        !props.isLoading &&
        !props.isUpdatingDimension &&
        /* Upgraded to 10.0.16 - START */
        !props.isLoadingDeliveryOptions &&
        !props.isUpdatingDeliveryOptions
        /* Upgraded to 10.0.16 - END */
    ) {
        return false;
    }

    return true;
};

const propogateResult = (props: IAddToCartComponentProps, result: ICartActionResult): void => {
    if (props.onAdd) {
        props.onAdd(result);
    }
};

const propogateError = (props: IAddToCartComponentProps, result: IAddToCartFailureResult): void => {
    if (props.onError) {
        props.onError(result);
    }
};

/* export const AddToCartComponent: React.FunctionComponent<IAddToCartComponentProps> = msdyn365Commerce.createComponent<IAddtoCartComponent>(
    'AddToCart',
    { component: AddToCart, ...AddToCartComponentActions }
); */

export default AddToCart;
