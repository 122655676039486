import { Collapse, Node } from '@msdyn365-commerce-modules/utilities';
import * as Msdyn365 from '@msdyn365-commerce/core';
import { AttributeValue } from '@msdyn365-commerce/retail-proxy';
import React, { useState } from 'react';

interface ICustomCollapseProps {
    child: AttributeValue[];
}
/**
 * VSI Customization
 * The CustomCollapse component renders collapse text along with collapse name. For each item, this collapse is called separately.
 * That's why multiple collapse can be expanded at a time.
 * @extends {React.PureComponent<ICustomCollapseProps>}
 */

const CustomCollapse: React.FC<ICustomCollapseProps> = props => {
    const [activeItem, SetActiveItem] = useState<number>(0);
    const _toggleCollapse = (selectedItem: number) => () => {
        selectedItem !== activeItem ? SetActiveItem(selectedItem) : SetActiveItem(-1);
    };
    return (
        <Node className='ms-tabs__list' tag='ul'>
            {props.child &&
                props.child
                    .filter((attribute: AttributeValue) => {
                        return attribute.TextValue;
                    })
                    .map((attribute: AttributeValue, index: number) => {
                        const isActive = activeItem === index;
                        return (
                            <Node className='ms-collapse-name' tag='li' key={index}>
                                <Node
                                    className={isActive ? 'ms-collapse-name_expanded' : 'ms-collapse-name_collapsed'}
                                    onClick={_toggleCollapse(index)}
                                    aria-expanded={isActive}
                                    tag='div'
                                    key={index}
                                >
                                    <strong>{attribute.Name}</strong>
                                </Node>
                                <Collapse className={'ms-collapse-text'} isOpen={isActive} timeout={100}>
                                    <Msdyn365.RichTextComponent text={attribute.TextValue} />
                                </Collapse>
                            </Node>
                        );
                    })}
        </Node>
    );
};

export default CustomCollapse;
